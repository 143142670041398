import React, { useState } from "react";
import api from "../utils/api";
import useSWR from "swr";
import format from "date-fns/format";
import DatePicker from "react-datepicker";
import Loading from "./Loading";

const fetcher = url => api.get(url).then(res => res.data);

function groupByDate(data) {
	if (data === undefined) { return undefined; }
	if(data && data.length) {
		const dates = {};
		data.forEach(it => {
			dates[it.lastRanDt] = dates[it.lastRanDt] || [];
			dates[it.lastRanDt].push(it);
		})
		return Object.keys(dates).map(key => ({ date: key, records: dates[key] }));
	}
	return null;
}

export default function({ id }) {
	const today = new Date();
	today.setHours(0,0,0,0);

	const [historyDate, setHistoryDate] = useState(today);

	const { data } = useSWR(`drycanyonfr/customersynctracking?cgId=${id}&date=${historyDate.toISOString()}`, fetcher)
	const dates = groupByDate(data);
	
	return (
		<>
			<div className="card card-body py-2 d-print-none">
				<div className="d-flex align-items-center justify-content-between">
					<h3 className="mb-3">History</h3>
					<div>
						<label>Filter By Date:</label> <DatePicker selected={historyDate} onSelect={date => setHistoryDate(date)}/>
					</div>
				</div>
				{
					dates && dates.length && dates.map(({ date, records }) => (
						<div class="mb-1">
							<h5>{format(new Date(date), "PPPPp")} MT</h5>
							<ul>
								{
									records.map(record => <li>{record.customer.first_name} {record.customer.last_name} changed <em>{record.isManual ? "manually" : "automatically"}</em> from <strong>{record.oldValue}</strong> to <strong>{record.newValue}</strong></li>)
								}
							</ul>
						</div>
					))
				}
				{
					dates === undefined && (
						<div className="text-center mt-0 mb-3"><Loading /></div>
					)
				}
				{
					dates === null && (
						<div className="text-center mt-3 mb-5"><em>There's no history available for this date.</em></div>
					)
				}
			</div>
		</>
	)
}