import React from "react";
import { observer } from "mobx-react-lite";
import repo from "../utils/repo";
import AppContainer from "./AppContainer";
import LoginContainer from "./LoginContainer";
import Loading from "./Loading";
import WaitAtLeast from "../utils/WaitAtLeast";
import AppStore from "../utils/AppStore";

function AppLoadingContainer() {
	const store = AppStore

	function checkVerification() {
		Promise.all([repo.verifyAuthentication(), WaitAtLeast(400)])
		.then(([isVerified, _]) => {
			store.isLoggedIn = isVerified
		})
	}

	if(store.isLoggedIn === null) {
		checkVerification()
	}

	return (
		<>
			{
				store.isLoggedIn === null ? (
					<Loading />
				) : (
					store.isLoggedIn ? <AppContainer /> : <LoginContainer />
				)
			}
		</>
	);
}

export default observer(AppLoadingContainer);
