import Axios from "axios";
import { reaction } from "mobx";
import AppStore from "./AppStore";

// note: duplicate .env.production to .env.local and change REACT_APP_API_BASE_URL to http://localhost:56199/api/ if you want to run locally.
const api = Axios.create({
	baseURL: process.env.REACT_APP_API_BASE_URL,//"http://localhost:56199/api/",//process.env.REACT_APP_API_BASE_URL,
	headers: {
		Authorization: `Bearer ${window.localStorage.getItem('token')}`
	}
});

reaction(
	() => AppStore.token,
	() => {
		api.defaults.headers.Authorization = `Bearer ${AppStore.token}`;
	}
);

export default api;
