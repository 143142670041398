import api from "./api";
import AppStore from "./AppStore";
import swal from "sweetalert";

const showGenericError = () => swal("Something has gone wrong!", "Please reload the page and try again.", "error")


async function request(req) {
	try {
		const res = await req
		if(res.data) {
			return res.data
		}
		return null;
	} catch (err) {
		showGenericError()
		return null
	}
}

const get = async (endpoint) => request(api.get(endpoint))
const put = async (endpoint, payload) => request(api.put(endpoint, payload))
const post = async (endpoint, payload) => request(api.post(endpoint, payload))

async function login(email, password) {
	password = btoa(password)
		const res = await api.post('drycanyonfr/account/login', { email, password })
		if(res.data && res.data.success) {
			const { token } = res.data;
			window.localStorage.setItem('token', token);
			AppStore.token = window.localStorage.getItem('token')
			AppStore.isLoggedIn = true;
			return true
		} else {
			swal("Something has gone wrong!", "Please check your account details and try again.", "error")
			return false;
		}
}

async function verifyAuthentication() {
	try {
		await api.get('drycanyonfr/account/verify');
		AppStore.token = window.localStorage.getItem('token')
		return true
	} catch (err) {
		return false
	}
}

function logout() {
	try {
		window.localStorage.removeItem('token');
		AppStore.token = null;
	} catch (err) {
		showGenericError()
	}
}

const getCustomerGroups = () => get('bigcommerce/customergroup')
const getCustomerTrackingGroups = () => get('drycanyonfr/customergrouptracking')
const getCustomers = () => get(`bigcommerce/customer`)
const updateCustomers = (lstFixedCredits) => put(`bigcommerce/customer/all`, lstFixedCredits)
const updateDefaults = (cgt) => put(`/drycanyonfr/customergrouptracking/${cgt.cgtId}`, cgt)
const addDefaults = (cgt) => post(`/drycanyonfr/customergrouptracking/`, cgt)


export default {
	login,
	logout,
	verifyAuthentication,
	getCustomerGroups,
	getCustomerTrackingGroups,
	getCustomers,
	updateCustomers,
	updateDefaults,
	addDefaults
}