import React from "react";
import AppStore from "../utils/AppStore";
import Header from "./Header";
import { Link, withRouter } from "react-router-dom";

const base_maintenance_url = /client\.mojoactive\.com|localhost/.test(window.location.hostname) ? 'https://yournewstore7.mybigcommerce.com' : 'https://drycanyonfr.com'

const Results = ({ headers, children }) => (
	<table className="table border border-default">
		<thead className="thead-light">
			<tr>
				{headers.map(it => <th>{it}</th>)}
			</tr>
		</thead>
		<tbody>
			{children}
		</tbody>
	</table>
)

const Summary = ({item: { store_credit, last_store_credit }}) => {
	if(store_credit > last_store_credit) { return <p className="m-0">Increased from ${last_store_credit} to ${store_credit}.</p> }
	else if (store_credit === last_store_credit) { return <p className="m-0">Left at ${store_credit}.</p> }
	return <p className="m-0">Set to ${store_credit} from ${last_store_credit}.</p>
}

const Customer = ({ item: { first_name, last_name, store_credit, email, id }, failed, item }) => (
	<tr>
		<td>{first_name} {last_name}</td>
		<td>{email}</td>
		<td>${store_credit}</td>
		<td>{ failed ? <a target="blank" href={`${base_maintenance_url}/manage/customers/${id}/edit`}>Please edit the customer manually.</a> : <Summary item={item} /> }</td>
	</tr>
)

const FailedResults = ({ results }) => (
	results.length ? (
		<>
			<h3 className="mb-1 pb-1 mt-4">Failed Results</h3>
			<p className="text-muted">Please review these values and set their store credit manually in the maintenance system.</p>
			<Results headers={['Name', 'Email', "Store Credit", ""]}>
				{
					results.map(it => <Customer failed={true} key={it.id} item={it} />)
				}
			</Results>
		</>
	) : null
)

function PreviewContainer({ history }) {
	if(AppStore.preview === null) {
		history.push('/')
		return null;
	}
	const group = AppStore.groups.filter(group => group.id === AppStore.preview.success[0].customer_group_id)[0]
	return (
		<>
			<Header />
			<div className="d-flex align-items-center justify-content-between">
				<h2>Customer Balance Adjustment Summary</h2>
				<div className="d-print-none">
					<Link className="btn btn-secondary mr-2" to="/">View Customer Groups</Link>
					<button onClick={() => window.print()} className="btn btn-primary">Print</button>
				</div>
			</div>
			<h3 className="my-2 mb-3 pb-1">{ group.name }</h3>
			<Results headers={['Name', 'Email', 'Store Credit', '']}>
				{
					AppStore.preview.success.map(it => <Customer key={it.id} item={it} />)
				}
			</Results>
			<FailedResults results={AppStore.preview.failure} />
		</>
	);
}

// export default observer(PreviewContainer);
export default withRouter(PreviewContainer);
