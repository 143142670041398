import React from "react";

function Loading() {
	return (
		<div className="centered-container">
			<div className="lds-ring"><div></div><div></div><div></div><div></div></div>
		</div>
	);
}

// export default observer(Loading);
export default Loading;
