import React, { useRef } from "react";
import { observer, useObservable, useComputed } from "mobx-react-lite";
import Header from "./Header";
import { Link, withRouter } from "react-router-dom";
import cn from "../utils/cn";
import AppStore from "../utils/AppStore";
import repo from "../utils/repo";
import debounce from "lodash/debounce";
import sortBy from "lodash/sortBy";
import CustomerGroupHistory from "./CustomerGroupHistory";

const Customer = observer(({ item, limit, value, value: { current, custom }, groupDefault }) => {
	const inputRef = useRef();
	const isOverLimit = useComputed(() => {
		let total = groupDefault + current;
		if(custom) {
			total = custom
		}
		return total > limit
	}, [groupDefault, current, limit, custom])

	const updateCustom = v => (value.custom = v);

	const handleFocus = () => {
		if(custom === null) {
			inputRef.current.value = (groupDefault + current)
		}
	}
	const handleBlur = () => {
		if(custom === null) {
			inputRef.current.value = null
		}
	}

	return (
		<tr>
			<td>{item.first_name}</td>
			<td>{item.last_name}</td>
			<td>${item.store_credit}</td>
			<td>
				<div className="row">
					<div className="col-12">
						<div className="input-group">
							<div className="input-group-prepend">
								<span className="input-group-text">$</span>
							</div>
							<input
								ref={inputRef}
								type="number"
								placeholder={(groupDefault + current) || ''}
								onFocus={handleFocus}
								onChange={e => updateCustom(e.target.value)}
								onBlur={handleBlur}
								className="form-control text-center"
							/>
						</div>
					</div>
				</div>
			</td>
			<td>
				<div className={cn("text-danger", isOverLimit ? "" : "invisible")}>
					This value is over the limit. It will be set to <strong>{limit}</strong>.
				</div>
			</td>
		</tr>
	);
});

function DefaultsEditor({ onSubmit, increment, max, day }) {
	const vm = useObservable({ increment, max, day });

	const updateDefault = value => (vm.increment = parseInt(value));
	const updateMax = value => (vm.max = parseInt(value));
	const updateDay = value => (vm.day = parseInt(value));

	const handleSubmit = debounce(() => {
		onSubmit(vm)
	}, 400)

	return (
		<div className="card card-body my-3">
			<div className="form-row">
				<div className="col">
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text">
								Increment Balance By
								</span>
						</div>
						<input
							defaultValue={increment || ""}
							onChange={e => {updateDefault(e.target.value); handleSubmit(e)}}
							type="number"
							min="0"
							className="form-control text-center"
						/>
					</div>
				</div>
				<div className="col">
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text">Max Amount</span>
						</div>
						<input
							defaultValue={max || ""}
							onChange={e => {updateMax(e.target.value); handleSubmit(e)}}
							type="number"
							className="form-control text-center"
						/>
					</div>
				</div>
				<div className="col">
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text">Sync Day</span>
						</div>
						<input
							defaultValue={day != null ? day : ""}
							onChange={e => { if ( parseInt(e.target.value) <= 28 && parseInt(e.target.value) >= 0) { updateDay(e.target.value);  handleSubmit(e); }}}
							type="number"
							min="0"
							max="28"
							className="form-control text-center"
						/>
						<div className={cn("text-danger", day > 28 || day < 0  ? "" : "invisible")}>
							<strong>The limit for this value is the 1st day to the 28th day.</strong>
						</div>
					</div>
				</div>
			</div>
			<div className="form-row">
				<div className="col" style={{ fontSize: 11 }}>Save Automatically on Edit</div>
			</div>
		</div>
	);
}

function CustomerGroupContainer({ match, history }) {
	const store = AppStore;
	const vm = useObservable({ submitting: false, submitted: false })
	const id = parseInt(match.params.id)
	const group = store.groups.filter(it => it.id === id)[0];

	let applied_values = {};
	group.customers.forEach(customer => {
		applied_values[customer.id] = {
			current: customer.store_credit,
			custom: null
		};
	});
	applied_values = useObservable(applied_values);

	const canSubmit = useComputed(() => {
		return vm.submitting || group.customers.length === 0 || !!!group.default
	})

	const customers_output = useComputed(() => {
		const customer_group_id = group.id;
		return Object.keys(applied_values).map(id => {
			const value = applied_values[id];
			let store_credit = value.custom ? value.custom : value.current + group.default
			if(store_credit > group.max) {
				store_credit = group.max;
			}
			id = parseInt(id);
			store_credit = parseFloat(store_credit);
			let current = value.current;
			return {
				id,
				customer_group_id,
				store_credit,
				current
			};
		});
	});

	const sorted_customers = useComputed(() => {
		return sortBy(group.customers, o => `${o.first_name} ${o.last_name}`)
	})

	async function handleDefaultsChange({ increment, max, day }) {
		vm.submitting = true;
		const cgtId = group.id
		const cgtPerMonthCredit = increment ? increment : group.default
		const cgtCreditMax = max ? max : group.max
		const cgtSyncDay = day <= 28 || day >= 0 ? day : group.day;
		const request = group.tracking_group_id ? repo.updateDefaults({ cgtId, cgtPerMonthCredit, cgtCreditMax, cgtSyncDay }) : repo.addDefaults({ cgtId, cgtPerMonthCredit, cgtCreditMax, cgtSyncDay })
		const res = await request;
		if(res) {
			group.default = res.cgtPerMonthCredit
			group.max = res.cgtCreditMax
			group.tracking_group_id = res.cgtId
			group.day = res.cgtSyncDay;
		}
		vm.submitting = false;
	} 

	async function apply(e) {
		vm.submitting = true;
		vm.submitted = true;
		e.preventDefault();
		const res = await repo.updateCustomers(customers_output);
		console.log(customers_output);
		if(res && res.success) {
			vm.submitted = true;
			let dict = {}
			group.customers.forEach(customer => dict[customer.id] = customer)
			let customer_dictionary = {}
			customers_output.forEach(customer => customer_dictionary[customer.id] = customer)

			res.success.forEach(r => {
				const id = r.id
				delete customer_dictionary[id];
				r.last_store_credit = dict[id].store_credit;
				dict[id].store_credit = r.store_credit;
			});

			// todo: move this into a reusable function.
			if(customer_dictionary) {
				const res = await repo.updateCustomers(Object.values(customer_dictionary))
				res.success.forEach(r => {
					const id = r.id
					delete customer_dictionary[id];
					r.last_store_credit = dict[id].store_credit;
					dict[id].store_credit = r.store_credit;
				})
			}

			AppStore.preview = res
			history.push('/preview')
		}
		vm.submitting = false;
	}

	return (
		<>
<Header />
{group ? (
				<div className="card card-body shadow">
					<h2 className="mt-3">{group.name}</h2>
					{
						group.name.toLowerCase().indexOf("terminated") >= 0 ? (<h3 className="alert-danger">Hourly termination process enabled.</h3>) : (<i></i>)
					}
					<DefaultsEditor onSubmit={handleDefaultsChange} max={group.max} increment={group.default} day={group.day} />
					{
						sorted_customers.length ? (
							<table className="table border border-default">
								<thead className="thead-light">
									<tr>
										<th>First Name</th>
										<th>Last Name</th>
										<th>Current Store Value</th>
										<th>New Value</th>
										<th />
									</tr>
								</thead>
								<tbody>
									{sorted_customers.map(item => (
										<Customer
											key={item.id}
											groupDefault={group.default}
											item={item}
											value={applied_values[item.id]}
											limit={group.max}
										/>
									))}
								</tbody>
							</table>
						) : (
							<div className="card card-body p-5 text-center mb-3">
								<h5>There are no customers for this group.</h5>
							</div>
						)
					}

					<CustomerGroupHistory id={id} />
					
					<div className="d-flex justify-content-between align-items-center mt-3">
						<Link to="/" className="btn btn-danger">
							Cancel
						</Link>
						<button onClick={apply} disabled={canSubmit} className="btn btn-success">
							Apply
						</button>
					</div>
				</div>
			) : null}
</>
	);
}

export default withRouter(observer(CustomerGroupContainer));
// export default CustomerItemContainer;
