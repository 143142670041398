import React from "react";
import { observer, useObservable, useComputed } from "mobx-react-lite";
import cn from "../utils/cn";
import repo from "../utils/repo";
const Field = observer(({ field }) => {
	function checkField() {
		if (field.value.trim() === "") {
			field.error = true;
		} else {
			field.error = false;
		}
		if (field.type === "email") {
			if (
				!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
					field.value
				)
			) {
				field.error = true;
			} else {
				field.error = false;
			}
		}
	}

	function updateField(value) {
		field.value = value;
		field.touched = true;
		checkField(field);
	}

	return (
		<div className="form-group">
			<label htmlFor={field.name.toLowerCase().replace(" ", "-")}>
				{field.name}
			</label>
			<input
				name={field.name.toLowerCase().replace(" ", "-")}
				type={field.type}
				onChange={e => updateField(e.target.value)}
				className={cn(
					"form-control",
					field.error ? "is-invalid" : field.touched ? "is-valid" : ""
				)}
			/>
			<div className="invalid-feedback">{field.feedback}</div>
		</div>
	);
});

const LoginContainer = () => {
	const vm = useObservable({
		submitting: false,
		form: [
			{
				name: "Email",
				type: "email",
				touched: false,
				value: "",
				error: false,
				feedback: "Please enter an email address."
			},
			{
				name: "Password",
				type: "password",
				touched: false,
				value: "",
				error: false,
				feedback: "Please enter a password."
			}
		]
	});

	const canSubmit = useComputed(() => {
		return vm.form.every(it => it.error === false && it.value !== "");
	}, [vm.form]);

	async function submit(e) {
		e.preventDefault();
		if (canSubmit) {
			vm.submitting = true;
			try {
				const email = vm.form.filter(it => it.type === "email")[0]
					.value;
				const password = vm.form.filter(it => it.type === "password")[0]
					.value;
				const succeeded = await repo.login(email, password);
				if (!succeeded) {
					// todo: bring in swal.
					// todo: prompt the user with an error.
				}
			} catch (err) {
				// todo: prompt the user with a generic failed error.
			}
			vm.submitting = false;
		}
	}

	return (
		<div className="centered-container login-container">
			<div className="card card-body shadow">
				<h2>Login</h2>
				<form onSubmit={submit}>
					{vm.form.map(field => (
						<Field key={field.name} field={field} />
					))}
					<div className="d-flex align-items-center justify-content-between">
						<div className="text-muted">
						{
							vm.submitting ? (
								'Loggin in. . .'
							) : null
						}
						</div>
						<button
							type="submit"
							disabled={!canSubmit}
							className="btn btn-success"
						>
							Login
						</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default observer(LoginContainer);
// export default LoginContainer;
