import React from "react";
import AppStore from "../utils/AppStore";

function Header() {
	const store = AppStore;

	function logout() {
		window.localStorage.removeItem('token')
		store.token = null
		store.isLoggedIn = null
	}

	return (
		<div className="card card-body my-3 py-2 shadow-sm d-print-none">
			<div className="d-flex align-items-center justify-content-between border-print-bottom">
				<h1>Dry Canyon</h1>
				<button onClick={logout} className="btn btn-outline-danger d-print-none">Logout</button>
			</div>
		</div>
	);
}

// export default observer(Header);
export default Header;
