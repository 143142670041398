import React from "react";
import { observer } from "mobx-react-lite";
import { HashRouter, Route } from "react-router-dom";
import CustomerGroupListContainer from "./CustomerGroupListContainer";
import CustomerGroupContainer from "./CustomerGroupContainer";
import repo from "../utils/repo";
import Loading from "./Loading";
import WaitAtLeast from "../utils/WaitAtLeast";
import AppStore from "../utils/AppStore";
import PreviewContainer from "./PreviewContainer";

function AppContainer() {
	const store = AppStore;
	
	if(store.groups === null) {
		Promise.all([
			repo.getCustomerGroups(),
			repo.getCustomerTrackingGroups(),
			repo.getCustomers(),
			WaitAtLeast(400)
		]).then(([ groups, tracking, customers ]) => {
			store.groups = groups.map(group => {
				const track = tracking.filter(it => it.cgtId === group.id)[0]
				const customer_list = customers.filter(it => it.customer_group_id === group.id ) || []
				if(track) {
					if (track.cgtCreditMax) { group.max = track.cgtCreditMax }
					if (track.cgtSyncDay) { group.day = track.cgtSyncDay }
					if(track.cgtPerMonthCredit) { group.default = track.cgtPerMonthCredit }
					if(track) { group.tracking_group_id = track.cgtId }
				}
				group.customers = customer_list
				return group
			})
		})
	}

	return (
			(store.groups === null) ? (
				<Loading />
			) : (
				<div className="container">
					<HashRouter>
						<Route path="/" exact component={CustomerGroupListContainer} />
						<Route path="/add/:id" component={CustomerGroupContainer} />
						<Route path="/preview" component={PreviewContainer} />
					</HashRouter>
				</div>
			)
	)
}

export default observer(AppContainer);
// export default AppContainer;
