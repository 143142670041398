import { observable } from "mobx";

const AppStore = observable({
	isLoggedIn: null,
	token: window.localStorage.getItem("token"),
	groups: null,
	preview: null
});

export default AppStore;
