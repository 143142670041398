import React from "react";
import { observer, useComputed } from "mobx-react-lite";
import Header from "./Header";
import { Link } from "react-router-dom";
import AppStore from "../utils/AppStore";
import sortBy from "lodash/sortBy"

const CustomerGroup = ({ item }) => (
	<tr>
		<td>{item.name}{
			item.name.toLowerCase().indexOf("terminated") >= 0 ? (<strong className="alert-danger ml-1">Hourly termination process enabled.</strong>) : (<i></i>)
		}</td>
		<td>{ item.default ? `$${item.default}` : 'None' }</td>
		<td>{item.max ? `$${item.max}` : 'None'}</td>
		<td>{item.day ? `${item.day}` : 'None'}</td>
		<td className="text-right">
			<Link to={`/add/${item.id}`} className="btn btn-success" >Edit</Link>
		</td>
	</tr>
)

function CustomerGroupListContainer() {

	const store = AppStore

	const sorted = useComputed(() => {
		return sortBy(store.groups, 'name')
	})

	return (
		<>
			<Header />
			<div className="card card-body shadow">
				<table className="table border border-default">
					<thead className="thead-light">
						<tr>
							<th>Customer Group</th>
							<th>Increment</th>
							<th>Max</th>
							<th>Sync Day</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{
							sorted.map(group => <CustomerGroup key={group.id} item={group} />)
						}
					</tbody>
				</table>
			</div>
		</>
	);
}

export default observer(CustomerGroupListContainer);
// export default CustomerListContainer;
