/**
 * A method that returns a promise that waits for the number of ms to resolve.
 * @param {number} ms The number of milliseconds before it resolves.
 */
export default function(ms = 400) {
	return new Promise(res => {
		setTimeout(() => {
			res()
		}, ms);
	})
} 